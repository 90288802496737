import React, { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import { Heading } from "../../utils/models";
// import  ScrollSpy from "react-scrollspy";
import scrollTo from "gatsby-plugin-smoothscroll";
import { Link } from "gatsby";
interface TocProps {
  /**
   * Callback triggered whenever the TOC is clicked. Used for
   * hiding the mobile toc overlay when clicking on a link.
   */
  onClick: () => void;
  tableOfContents: string;
  headings: Array<Heading>;
}

const StyledNav = styled.nav`
  > ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
      line-height: 1.2em;
      list-style-type: none;
      padding-top: 0.3em;
      padding-bottom: 0.3em;
      &:last-child {
        padding-bottom: 0;
      }
    }
    ul,
    li,
    ol {
      margin-left: 0.2rem;
      padding-inline-start: 0.2rem;
    }
  }

  a {
    color: #808080;
    text-decoration: none;
    &:hover {
      cursor: pointer;
    }
  }

  .is-active {
    color: #404040;
    font-weight: 700;
  }
`;
const Toc: FunctionComponent<TocProps> = ({
  onClick,
  tableOfContents,
  headings,
}) => {
  const ids = headings.map((x) => x.id);
  // const urls = headings.map(x => `${window.location}/#${x.id}`)
  const activeId = useActiveId(ids);
  return (
    <StyledNav className={`toc-list`} onClick={onClick}>
      {/* <ScrollSpy items={ids} currentClassName="is-active"> */}
      {renderItems(headings, activeId)}
      {/* </ScrollSpy> */}
    </StyledNav>
  );
};

export default Toc;

function renderItems(items: Heading[], activeId) {
  if (!items || items.length == 0) {
    return;
  }
  const parentDepth = items[0].depth;
  return (
    <ul>
      {items.map((item, index) => {
        if (item.depth <= parentDepth) {
          // check if next item is still child or not
          const nextIndex = index + 1;
          let endIndex = items.length;
          let hasChild = false;
          for (let i = nextIndex; i < items.length; i++) {
            if (items[i].depth <= parentDepth) {
              endIndex = i;
              break;
            }
          }
          if (
            nextIndex < items.length &&
            items[nextIndex].depth > parentDepth
          ) {
            hasChild = true;
          }
          return (
            <li key={item.id}>
              <Link
                onClick={() => scrollTo(`#${item.id}`)}
                // href={`#${item.id}`}
                to="#"
                className={activeId === item.id ? "is-active" : "non-active"}
              >
                {item.value}
              </Link>
              {hasChild &&
                renderItems(items.slice(nextIndex, endIndex), activeId)}
            </li>
          );
        }
      })}
    </ul>
  );
}

function useActiveId(itemIds: string[]) {
  const [activeId, setActiveId] = useState(`activeId`);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveId(entry.target.id);
          }
        });
      },
      { rootMargin: `0% 0% -80% 0%` }
    );
    itemIds.forEach((id) => {
      const element = document.getElementById(id);
      if (element) {
        observer.observe(element);
      }
    });
    return () => {
      itemIds.forEach((id) => {
        const element = document.getElementById(id);
        if (element) {
          observer.unobserve(element);
        }
      });
    };
  }, [itemIds]);
  return activeId;
}
// https://nickymeuleman.netlify.app/blog/table-of-contents
// https://markmichon.com/advanced-custom-mdx-components#fnref-1
