import styled from "styled-components";
import { Container } from "../components/common";
import Theme from "../styles/theme";
import Img from "gatsby-image";
import { Link } from "gatsby";

export const PostContainer = styled(Container)`
  display: flex;
  justify-content: center;
  padding: 0 !important;
`;

export const LeftSidebar = styled.div<{ show?: boolean }>`
  min-width: 255px;
  max-width: 225px;
  transition: opacity 0.5s;

  @media (max-width: ${Theme.breakpoints.xl}) {
    position: fixed;
    /*opacity: ${(props) => (props.show ? 1 : 0)};*/
    display: ${(props) => (props.show ? "block" : "none")};
    z-index: 1000;
    background-color: #fff;
    width: 100% !important;
    max-width: 100%;
    padding: 0 20px;
    margin-top: -5px;
    height: calc(100vh - 70px);
  }
`;

export const PostContent = styled.div`
  margin-top: -5px;
  border-right: 1px #e5eff5 solid;
  border-left: 1px #e5eff5 solid;
  background-color: #fff;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.03), 0 3px 46px rgba(0, 0, 0, 0.1);
  z-index: 10;
  width: 1035px;
  max-width: 100%;
  li > a,
  p > a {
    color: ${Theme.layout.linkColor};
    border-bottom: 2px ${Theme.layout.linkColor} solid;
    overflow-wrap: break-word;
  }

  pre {
    margin: 30px 0;
  }

  blockquote {
    border-left: 4px ${Theme.layout.primaryColor} solid;
    background-color: ${Theme.layout.backgroundColor};
    margin: 30px 0;
    padding: 5px 20px;
    border-radius: 0.3em;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    scroll-margin-top: 4.7rem;
  }

  h3::before,
  h4::before,
  h5::before,
  h6::before {
    display: block;
    content: " ";
    visibility: hidden;
  }

  h2 {
    .anchor.before {
      padding-top: 10px;
    }
    border-top: 1px solid #ececec;
    margin-top: 44px;
    padding-top: 15px;
    line-height: 1.2;
  }

  code[class*="language-text"] {
    padding: 5px;
  }

  p > img {
    max-width: 100%;
    border-radius: 0.3em;
    margin: 30px 0;
  }

  hr {
    border-top: 1px solid #ececec;
    border-bottom: 0;
    margin-top: 44px;
    margin-bottom: 40px;
  }

  .gatsby-resp-image-link {
    margin: 30px 0;
    max-width: 100%;

    .gatsby-resp-image-image {
      border-radius: 0.3em;
    }
  }
  .table-wrapper {
    table {
      width: 100%;
    }
    max-width: 100%;
    overflow-x: scroll;
  }
  table {
    margin: 0 auto;
  }

  /* Default Table Style */
  table {
    color: #333;
    background: white;
    border: 1px solid grey;
    font-size: 12pt;
    border-collapse: collapse;
  }
  table thead th,
  table tfoot th {
    color: #474747;
    background: rgb(102 102 102 / 10%);
  }
  table caption {
    padding: 0.5em;
  }
  table th,
  table td {
    padding: 0.5em;
    border: 1px solid lightgrey;
  }
  /* Zebra Table Style */
  [data-table-theme*="zebra"] tbody tr:nth-of-type(odd) {
    background: rgba(0, 0, 0, 0.05);
  }
  [data-table-theme*="zebra"][data-table-theme*="dark"]
    tbody
    tr:nth-of-type(odd) {
    background: rgba(255, 255, 255, 0.05);
  }
  /* Dark Style */
  [data-table-theme*="dark"] {
    color: #ddd;
    background: #333;
    font-size: 12pt;
    border-collapse: collapse;
  }
  [data-table-theme*="dark"] thead th,
  [data-table-theme*="dark"] tfoot th {
    color: #aaa;
    background: rgba(0255, 255, 255, 0.15);
  }
  [data-table-theme*="dark"] caption {
    padding: 0.5em;
  }
  [data-table-theme*="dark"] th,
  [data-table-theme*="dark"] td {
    padding: 0.5em;
    border: 1px solid grey;
  }
  sup {
    scroll-margin-top: 5.5rem;
  }
`;

export const TocWrapper = styled.div`
  position: sticky;
  top: 70px;
  padding: 40px 30px 40px 0;
`;

export const PostHeader = styled.header`
  padding: 40px;

  @media (max-width: ${Theme.breakpoints.sm}) {
    padding: 20px;
  }
`;

export const FeaturedImage = styled(Img)`
  border-radius: 0;

  @media (max-width: ${Theme.breakpoints.xl}) {
    margin-left: -1px;
    margin-right: -1px;
  }
`;

export const StyledPost = styled.section`
  padding: 40px;

  @media (max-width: ${Theme.breakpoints.sm}) {
    padding: 20px;
  }
  p {
    margin-bottom: 0.5em;
  }
`;

export const PostMeta = styled.section`
  display: flex;
  justify-content: space-between;
  opacity: 0.8;
  font-size: 0.9em;
`;

export const PostTitle = styled.h1`
  margin: 0;
  padding: 0;
  line-height: 1.2;
`;

export const PostFooter = styled.footer`
  background-color: #fafafa;
  font-size: 0.8em;
  color: #666;
  padding: 40px;
  line-height: 1em;

  p {
    margin: 5px 0;
  }
`;

export const FooterTagLink = styled(Link)`
  color: #000 !important;
  text-decoration: none;
  border-bottom: 0 !important;
`;

export const PostAddition = styled.section`
  background-color: #fff;
  border-top: 1px #e5eff5 solid;
  border-bottom: 1px #e5eff5 solid;
  z-index: 700;
  position: relative;
  padding: 40px;
`;

export const PostAdditionContent = styled(Container)`
  display: flex;
  justify-content: space-between;
`;

export const BioWrapper = styled.div`
  width: 50%;
  margin: auto;

  @media (max-width: ${Theme.breakpoints.sm}) {
    width: 100%;
  }
`;

export const ToggleTocButton = styled.button`
  display: flex;
  padding-top: 1em;
  position: fixed;
  justify-content: center;
  right: 20px;
  bottom: 20px;
  border-radius: 100%;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.03), 0 3px 46px rgba(0, 0, 0, 0.1);
  border: 0;
  z-index: 5000;
  width: 50px;
  height: 50px;
  background-color: #20232a;
  color: #fff;
  outline: none;

  @media (min-width: ${Theme.breakpoints.xl}) {
    display: none;
  }
`;
