import { Link } from "gatsby";
import styled from "styled-components";
export const LinkedPostWrapper = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  overflow: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 576px) {
    display: block;
  }
  gap: 0.6rem;
`;

export const LinkedPost = styled(Link)`
  display: block;
  background-color: #ffff;
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 5px 10px;
  font-size: 16px;
  text-decoration: none;
  color: #333;
  height: 100%;
  border: 1px solid #dadde1;
  &:hover {
    border: 1px solid #009400;
  }
  .label {
    color: #067a5f;
    font-weight: 600;
  }
  @media (max-width: 576px) {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
`;

export const PrevPost = styled.div`
  text-align: left;
  .label {
    &:before {
      content: "« ";
    }
  }
`;

export const NextPost = styled.div`
  text-align: right;
  grid-column: 2/3;
  .label {
    &:after {
      content: " »";
    }
  }
`;

// .sublabel {
//   text-align: right;
// }
