import React, { createRef, FunctionComponent, useState } from "react";
import Layout from "../components/layout";
import { Post, Tag } from "../utils/models";
import Toc from "../components/toc";
import ReadingProgress from "../components/reading-progress";
import { graphql, Link } from "gatsby";
import slugify from "slugify";
import Bio from "../components/bio";
import Comments from "../components/comments";
import SEO from "../components/seo";
import { FaAlignJustify, FaTimes } from "react-icons/fa";
import {
  BioWrapper,
  FeaturedImage,
  FooterTagLink,
  LeftSidebar,
  PostAddition,
  PostAdditionContent,
  PostContainer,
  PostContent,
  PostFooter,
  PostHeader,
  PostMeta,
  PostTitle,
  StyledPost,
  TocWrapper,
  ToggleTocButton,
} from "./postStyle";
import { LinkedPostNav, LinkedPostNavProps } from "../components/linked-posts";

interface PostTemplateProps {
  data: {
    primaryTag: Tag | null;
    post: Post;
    prev?: Post;
    next?: Post;
  };
  location: Location;
}

const PostTemplate: FunctionComponent<PostTemplateProps> = ({
  data,
  location,
}) => {
  const [showToc, setShowToc] = useState<boolean>(false);
  const post = data.post;
  const readingProgressRef = createRef<HTMLElement>();
  const primaryTag = data.primaryTag;
  const toggleToc = () => setShowToc(!showToc);
  const linkedPosts: LinkedPostNavProps = {
    prev: data?.prev
      ? {
          title: data.prev?.frontmatter.title,
          link: data.prev?.frontmatter.path,
        }
      : undefined,
    next: data?.next
      ? {
          title: data.next?.frontmatter.title,
          link: data.next?.frontmatter.path,
        }
      : undefined,
  };
  return (
    <Layout bigHeader={false}>
      <SEO
        location={location}
        title={post.frontmatter.title}
        publishedAt={post.frontmatter.created}
        updatedAt={post.frontmatter.updated}
        tags={post.frontmatter.tags}
        description={post.frontmatter.excerpt}
        image={
          post.frontmatter.featuredImage
            ? post.frontmatter.featuredImage.childImageSharp.fluid.src
            : null
        }
      />
      <ReadingProgress
        target={readingProgressRef}
        color={primaryTag ? primaryTag.color : undefined}
      />
      <PostContainer>
        {post.headings.find((h) => h.depth > 1) && (
          <>
            <LeftSidebar show={showToc}>
              <TocWrapper>
                <Toc
                  onClick={toggleToc}
                  tableOfContents={post.tableOfContents}
                  headings={post.headings}
                />
              </TocWrapper>
            </LeftSidebar>
            <ToggleTocButton
              role={`button`}
              aria-label={`Toggle table of contents`}
              onClick={toggleToc}
            >
              {showToc ? <FaTimes /> : <FaAlignJustify />}
            </ToggleTocButton>
          </>
        )}
        <PostContent>
          <article className={`post`} ref={readingProgressRef}>
            <PostHeader>
              <PostMeta>
                {post.frontmatter.tags.length > 0 && (
                  <Link
                    to={`/tag/${slugify(post.frontmatter.tags[0], {
                      lower: true,
                    })}`}
                  >
                    {post.frontmatter.tags[0]}
                  </Link>
                )}
                <time dateTime={post.frontmatter.created}>
                  {post.frontmatter.createdPretty}
                </time>
              </PostMeta>
              <PostTitle>{post.frontmatter.title}</PostTitle>
            </PostHeader>
            {post.frontmatter.featuredImage && (
              <FeaturedImage
                fluid={post.frontmatter.featuredImage.childImageSharp.fluid}
                loading="eager"
              />
            )}
            <StyledPost
              dangerouslySetInnerHTML={{ __html: post.html }}
              className={`post`}
            />
            <PostFooter>
              <p>
                Published under&nbsp;
                {post.frontmatter.tags.map((tag, index) => (
                  <span key={index}>
                    <FooterTagLink to={`/tag/${slugify(tag, { lower: true })}`}>
                      {tag}
                    </FooterTagLink>
                    {post.frontmatter.tags.length > index + 1 && <>, </>}
                  </span>
                ))}
                &nbsp;on{" "}
                <time dateTime={post.frontmatter.created}>
                  {post.frontmatter.createdPretty}
                </time>
                .
              </p>
              {post.frontmatter.updated !== post.frontmatter.created && (
                <p>
                  Last updated on{" "}
                  <time dateTime={post.frontmatter.updated}>
                    {post.frontmatter.updatedPretty}
                  </time>
                  .
                </p>
              )}
            </PostFooter>
            <LinkedPostNav
              prev={linkedPosts.prev}
              next={linkedPosts.next}
            ></LinkedPostNav>
            <Comments />
          </article>
        </PostContent>
      </PostContainer>
      <PostAddition>
        <PostAdditionContent>
          <BioWrapper>
            <Bio textAlign={`center`} showName={true} />
          </BioWrapper>
        </PostAdditionContent>
      </PostAddition>
    </Layout>
  );
};

export default PostTemplate;

export const query = graphql`
  query PrimaryTag(
    $postId: String!
    $primaryTag: String!
    $prevId: String
    $nextId: String
  ) {
    post: markdownRemark(id: { eq: $postId }) {
      headings {
        id
        depth
        value
      }
      tableOfContents(maxDepth: 4)
      frontmatter {
        title
        path
        tags
        excerpt
        created
        createdPretty: created(formatString: "DD MMMM, YYYY")
        updated
        updatedPretty: created(formatString: "DD MMMM, YYYY")
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800, quality: 100, webpQuality: 100) {
              base64
              aspectRatio
              srcWebp
              srcSetWebp
              src
              srcSet
              sizes
            }
          }
        }
      }
      html
    }
    primaryTag: tags(name: { eq: $primaryTag }) {
      name
      color
    }
    prev: markdownRemark(id: { eq: $prevId }) {
      frontmatter {
        title
        path
      }
    }
    next: markdownRemark(id: { eq: $nextId }) {
      frontmatter {
        title
        path
      }
    }
  }
`;
