import React from 'react';
import { LinkedPost, PrevPost, NextPost, LinkedPostWrapper } from './style';

interface LinkedPostLinkProps {
  title: string;
  link: string;
}

export interface LinkedPostNavProps {
  prev?: LinkedPostLinkProps;
  next?: LinkedPostLinkProps;
}

export const LinkedPostNav: React.FC<LinkedPostNavProps> = ({ prev, next }) => {
  return (
    <LinkedPostWrapper>
      {prev && (
        <PrevPost>
          <LinkedPost to={prev.link}>
            <div className="sublabel">Previous</div>
            <div className="label">{prev.title}</div>
          </LinkedPost>
        </PrevPost>
      )}
      {next && (
        <NextPost>
          <LinkedPost to={next.link}>
            <div className="sublabel">Next</div>
            <div className="label">{next.title}</div>
          </LinkedPost>
        </NextPost>
      )}
    </LinkedPostWrapper>
  );
};
