import React, { FunctionComponent, useEffect, useRef } from "react";
import styled from "styled-components";
import Giscus from "@giscus/react";
import Theme from "../../styles/theme";

const PostComment = styled.div`
  margin: 2rem auto;
  @media (max-width: ${Theme.breakpoints.md}) {
    max-width: 95%;
  }
  max-width: 95%;
`;
/**
 * Placeholder which is attached under every post. Can be shadowed to
 * quickly integrate comments (like commento, Disqus, ...).
 */
const Comments: FunctionComponent = () => {
  const divRef = useRef(null);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://giscus.app/client.js";
    script.dataset.repo = "devgiangho/devgiangho.github.io";
    script.dataset.repoId = "MDEwOlJlcG9zaXRvcnkyMTI3MzU1ODA=";
    script.dataset.category = "Comments";
    script.dataset.categoryId = "DIC_kwDODK4WXM4CU-Zi";
    script.dataset.mapping = "title";
    script.dataset.reactionsEnabled = "1";
    script.dataset.emitMetadata = "0";
    script.dataset.theme = "light";
    script.dataset.lang = "en";
    script.crossOrigin = "anonymous";
    script.async = true;
    divRef?.current?.appendChild(script);

    return () => {
      const scripts = divRef?.current?.getElementsByTagName("script");
      if (!scripts){
        return;
      }
      for (let i = 0; i < scripts.length; i++) {
        if (scripts[i].src.startsWith("https://giscus.app")) {
          scripts[i].remove();
        }
      }
    };
  }, []);
  return (
    <PostComment>
      <div ref={divRef}></div>
    </PostComment>
  );
};

export default Comments;

{
  /* <Giscus
      repo="devgiangho/kiendinh.space"
      repoId="MDEwOlJlcG9zaXRvcnkyMTI3MzU1ODA="
      category="Comments"
      categoryId="DIC_kwDODK4WXM4CU-Zi"
      mapping="title"
      strict="0"
      reactionsEnabled="1"
      emitMetadata="0"
      inputPosition="top"
      theme="light"
      lang="en"
      // loading="lazy"
      cross-origin="anonymous"
    /> */
}
